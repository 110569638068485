import classes from "./icons.module.css";

export default function UpdateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={classes.icon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.349 5.64165C22.4479 5.79187 22.492 5.97163 22.4738 6.1506C22.4556 6.32956 22.3762 6.49675 22.249 6.62394L12.6729 16.1989C12.575 16.2968 12.4527 16.3669 12.3188 16.4021L8.33022 17.4437C8.19838 17.4781 8.05983 17.4774 7.92834 17.4417C7.79685 17.406 7.67698 17.3365 7.58063 17.2402C7.48428 17.1439 7.41481 17.024 7.37911 16.8925C7.3434 16.761 7.34271 16.6225 7.37709 16.4906L8.41876 12.5031C8.44917 12.3834 8.50653 12.2722 8.58647 12.1781L18.1979 2.5729C18.3444 2.4266 18.543 2.34442 18.75 2.34442C18.957 2.34442 19.1556 2.4266 19.3021 2.5729L22.249 5.51873C22.2863 5.55624 22.3199 5.59742 22.349 5.64165ZM20.5917 6.07082L18.75 4.23019L9.8771 13.1031L9.22605 15.5958L11.7188 14.9448L20.5917 6.07082Z"
        fill="#228699"
      />
      <path
        d="M20.4594 17.875C20.7441 15.4416 20.835 12.9894 20.7312 10.5416C20.729 10.484 20.7386 10.4265 20.7596 10.3727C20.7806 10.3189 20.8124 10.2701 20.8531 10.2291L21.8781 9.20415C21.9061 9.17598 21.9417 9.1565 21.9805 9.14805C22.0193 9.1396 22.0597 9.14254 22.0969 9.15651C22.1341 9.17048 22.1664 9.19489 22.1901 9.22681C22.2137 9.25873 22.2277 9.2968 22.2302 9.33644C22.4231 12.244 22.3499 15.1631 22.0114 18.0573C21.7656 20.1635 20.0739 21.8146 17.9771 22.0489C14.3368 22.4521 10.6632 22.4521 7.02289 22.0489C4.92706 21.8146 3.23435 20.1635 2.98852 18.0573C2.55664 14.365 2.55664 10.635 2.98852 6.94269C3.23435 4.83644 4.92602 3.1854 7.02289 2.95103C9.78578 2.64465 12.5695 2.57043 15.3448 2.72915C15.3845 2.732 15.4226 2.74618 15.4545 2.77C15.4864 2.79381 15.5108 2.82628 15.5248 2.86355C15.5389 2.90081 15.5419 2.94132 15.5336 2.98027C15.5253 3.01922 15.5061 3.05498 15.4781 3.08332L14.4437 4.11665C14.4032 4.15701 14.3549 4.18865 14.3017 4.20961C14.2484 4.23057 14.1915 4.24041 14.1344 4.23852C11.8185 4.1598 9.49993 4.24857 7.19685 4.50415C6.52387 4.57864 5.89566 4.87782 5.41368 5.35337C4.9317 5.82892 4.62412 6.45307 4.5406 7.12498C4.12294 10.6961 4.12294 14.3038 4.5406 17.875C4.62412 18.5469 4.9317 19.171 5.41368 19.6466C5.89566 20.1221 6.52387 20.4213 7.19685 20.4958C10.6916 20.8864 14.3083 20.8864 17.8041 20.4958C18.4771 20.4213 19.1053 20.1221 19.5873 19.6466C20.0693 19.171 20.3758 18.5469 20.4594 17.875Z"
        fill="#228699"
      />
    </svg>
  );
}
