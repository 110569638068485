import React, { useEffect, useState } from "react";
import Hero from "../../blogComponents/Hero";
import { ArrowLeft, ArrowRight, Share2 } from "lucide-react";
import "animate.css";
import { Link } from "react-router-dom";
import "../../input.css";
import axios from "axios"
import Loader from "../../blogComponents/LoaderComponent";
import { Helmet } from "react-helmet-async";
import logo from "../../assets/icons/logo192.png";


const HomePage = () => {
  //base uri
  const url = process.env.REACT_APP_BACKEND_SERVER_URL;

  //if user reloads it doesnt start the blog from page one
  const savedPage = Number(localStorage.getItem("currentPage")) || 1;

  const [currentPage, setCurrentPage] = useState(savedPage);
  const [post, setPost] = useState([]);

  //for the loading screen 
  const [loading, setLoading] = useState(true);

  useEffect(()=> {
    const newPost = async () => {
      try {
        const response = await axios.get(`${url}/posts/`);
        setPost(response.data)
        console.log(response)
      } catch (error) {
        console.log(error.message)
      }finally{
        setLoading(false)
      }
    };
    newPost();
  }, []);
  
  // Save currentPage to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

//logic for pagination
  const postsPerPage = 4;
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = post.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(post.length / postsPerPage);

  const handleShare = async (item) =>{
    if (navigator.share){
      try {
        await navigator.share({
          title: item.Title,
          text: item.paragraph1,
          url: "/posts/"+item.Title,
        });
        await axios.post(`${url}/posts/${item._id}/share`);
      } catch (error) {
        console.error('Error sharing', error);
      }
    }else{
      alert("browser not supported")
    }
    }

  /// show loading screen 
  if(loading){
    return <Loader/>
  }

  return (
    <main className="bg-homebg">
      <Helmet>
        <title>Music Distribution Tips & Music Industry Insights | Soundmac Blog</title>
        <meta
          name="description"
          content="Stay updated with Soundmac’s blog! Discover music distribution tips, industry news, and marketing strategies for African artists to grow their fanbase and maximize streaming revenue."
        />
        <link rel="canonical" href="/blog" />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="Music distribution, Industry news, Africa artists, Fan base"/>
        <meta property="og:type" content="article" />
        <meta property="og:url" content="/blog" />
        <meta property="og:image" content={logo} />
        <meta name="twitter:card" content="summary_large"/>
      </Helmet>
      <div>
        <Hero />
      </div>
      <section className="">
        <div className="">
          <div className="mx-auto max-w-[70%] max-md:max-w-[90%] full">
            <h1 className="animate__animated animate__bounce my-11 text-4xl font-bold capitalize text-text">
              recent posts
            </h1>
            <section className="grid grid-cols-1 gap-5 md:grid-cols-2">
              {currentPosts.map((item) => (
                <article
                  key={item._id}
                  className="flex h-full rounded-2xl bg-white p-3 hover:shadow-lg"
                >
                  <div className="flex flex-col justify-between gap-5 w-full h-full">
                    <Link to={`/posts/${item.Title}`} className="w-full h-full">
                      <div className="w-full h-[90%]">
                        <img
                          src={item.image1}
                          alt="Blog image"
                          className="object-cover h-full w-full"
                        />
                      </div>
                      <h2 className="font-semibold leading-normal text-text md:text-[2vw] truncate">
                        {item.Title}
                      </h2>
                    </Link>
                    <div>
                      <p className="text-sm mb-3">{item.date.split("T")[0]}</p>
                      <div className="flex items-baseline justify-between align-baseline text-base font-semibold">
                        <p className="flex gap-3 text-icon">
                          <button onClick={()=> {
                            handleShare(item)
                          }}>
                            <Share2
                              stroke="#10414A"
                              className={"hover:scale-110 focus:scale-110"}
                            />
                          </button>
                          {item.shares}
                        </p>
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </section>
          </div>
        </div>
        <div className="mx-auto flex max-w-[70%] justify-between gap-2 py-14 full">
          <button
            className="rounded-full border border-black p-3 hover:bg-icon hover:text-white max-md:p-2"
            onClick={() => {
              setCurrentPage((prev) => Math.max(prev - 1, 1));
              window.scrollTo(0, 500);
            }}
            disabled={currentPage === 1}
          >
            <ArrowLeft />
          </button>
          <div className="flex gap-2">
            {[...Array(totalPages).keys()].map((num) => (
              <button
                className={
                  "rounded-full px-5 py-2 shadow-md hover:bg-icon hover:text-white max-md:px-4 max-md:py-1" +
                  (currentPage === num + 1
                    ? " bg-icon text-white"
                    : " bg-white text-black")
                }
                key={num + 1}
                onClick={() => {
                  window.scrollTo(0, 500);
                  setCurrentPage(num + 1);
                }}
                disabled={currentPage === num + 1}
              >
                {num + 1}
              </button>
            ))}
          </div>
          <button
            className="rounded-full border border-black p-3 hover:bg-icon hover:text-white max-md:p-2"
            onClick={() => {
              window.scrollTo(0, 500);
              setCurrentPage((prev) => Math.min(prev + 1, totalPages));
            }}
            disabled={currentPage === totalPages}
          >
            <ArrowRight />
          </button>
        </div>
        <div className="mx-auto max-w-[70%] full">
          <h3 className="text-[1.5vw] font-semibold capitalize max-md:text-lg">
            dont miss a beat!
          </h3>
          <p className="text-sm">
            Subscribe to our newsletter <br />
            for the latest updates straight to your inbox.
          </p>
          <form action="" className="flex flex-col gap-5">
            <input
              type="email"
              placeholder="Email Address"
              className="max-w-[60%] rounded-[5px] bg-icon bg-opacity-[20%] p-2 outline-icon max-md:max-w-full"
            />
            <button
              className="mb-44 max-w-[30%] rounded-[5px] bg-icon p-1 text-white hover:bg-opacity-[90%] max-md:max-w-full"
              type="submit"
            >
              Subscribe
            </button>
          </form>
        </div>
      </section>
      {/* <AdComponent/> */}
    </main>
  );
};

export default HomePage;
