import classes from "./twitter.module.css";

export default function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 30 31"
      fill="none"
      className={classes.icon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3031 16.2743C12.0896 15.5815 3.1493 3.37323 2.90886 2.74232L9.99384 2.76961C10.3383 3.04668 11.2938 4.54816 11.6138 4.99319C12.1063 5.67826 12.7045 6.50285 13.2025 7.25574C13.519 7.7335 16.1096 11.501 16.4054 11.6413C16.7838 11.3802 22.2668 5.06176 23.0274 4.17366C24.1614 2.85027 23.9339 2.56229 26.2412 2.75519C26.129 3.10863 25.3882 3.82878 25.1567 4.08091C24.7433 4.53062 24.4346 4.87745 24.027 5.34469L20.6807 9.17885C20.2809 9.64414 19.9294 9.99019 19.5506 10.445C19.1742 10.8966 18.7888 11.2945 18.424 11.7091C18.1976 11.9667 17.4814 12.748 17.3473 13.0255C17.4806 13.3856 18.2483 14.4342 18.5316 14.8325C19.3527 15.9879 26.9653 26.7321 27.0771 27.0598L19.9115 27.0294C19.311 26.1425 13.5494 17.8012 13.2427 17.65C12.7922 17.9617 9.36721 22.0562 8.62641 22.8574L5.17102 26.8393C4.74353 27.2617 3.59822 27.0816 2.9642 27.0516C3.10604 26.7364 7.2999 22.0371 7.64867 21.6225C8.46975 20.6467 9.26395 19.8564 10.0238 18.9543C10.4307 18.4714 12.1177 16.6325 12.3028 16.274L12.3031 16.2743ZM29.948 28.7562C30.045 28.3708 29.9748 22.2199 29.9838 21.2328C30.0403 15.0344 29.8985 8.58418 29.9834 2.42395C29.9978 1.3675 29.6919 0.420556 28.5591 0.105685C27.8136 -0.10163 22.365 0.0616456 21.1078 0.052293C14.8926 0.00708888 8.49197 0.125945 2.29861 0.052293C1.19696 0.0390435 0.262863 0.510169 0.0551578 1.5409C-0.0539557 2.08218 0.0348893 7.9521 0.0212501 9.09273C-0.0512324 15.2904 0.0937334 21.7101 0.0220303 27.9016C0.0150158 28.5083 0.0528193 29.0106 0.347036 29.4264C0.571888 29.744 1.02627 30.0975 1.4409 30.2124C2.2803 30.4447 7.56528 30.2639 8.88244 30.2732L27.6924 30.274C28.796 30.2748 29.6615 29.8964 29.9484 28.7562H29.948Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9145 25.6043C20.3569 24.7404 19.6079 23.7911 18.9976 22.9151L13.3183 15.0001C12.5736 14.0181 5.89663 4.66976 5.80622 4.30618L9.06949 4.31824C9.75612 5.12451 12.0175 8.43612 12.8811 9.62546C13.7555 10.8296 24.0379 25.0124 24.1907 25.602L20.9145 25.604V25.6043ZM12.3031 16.2748C12.1176 16.6333 10.4311 18.4722 10.0242 18.955C9.26433 19.8572 8.47014 20.6475 7.64906 21.6233C7.30029 22.0379 3.10644 26.7372 2.96459 27.0524C3.59862 27.0821 4.74392 27.2625 5.17141 26.8401L8.6268 22.8582C9.36722 22.057 12.7922 17.9625 13.2431 17.6508C13.5498 17.8016 19.3113 26.1433 19.9119 27.0302L27.0775 27.0606C26.9657 26.7329 19.3531 15.9887 18.532 14.8333C18.2487 14.435 17.4814 13.3864 17.3477 13.0263C17.4818 12.7488 18.1984 11.9671 18.4244 11.7099C18.7892 11.2953 19.1742 10.8974 19.551 10.4458C19.9298 9.99098 20.2813 9.64454 20.6811 9.17964L24.0274 5.34548C24.435 4.87824 24.7433 4.53141 25.1571 4.08171C25.389 3.82958 26.1294 3.10943 26.2416 2.75598C23.9346 2.56308 24.1618 2.85106 23.0278 4.17445C22.2668 5.06255 16.7842 11.3806 16.4058 11.6421C16.11 11.5018 13.5194 7.73429 13.203 7.25653C12.7049 6.50403 12.1067 5.67906 11.6142 4.99398C11.2942 4.54895 10.3387 3.04786 9.99422 2.7704L2.90926 2.74311C3.1497 3.37402 12.09 15.5827 12.3035 16.2751L12.3031 16.2748Z"
        fill="#FCFCFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9145 25.6043L24.1907 25.6024C24.0379 25.0128 13.7555 10.8299 12.881 9.6258C12.0175 8.43646 9.75611 5.12487 9.06948 4.3186L5.80621 4.30652C5.89662 4.6701 12.5732 14.0184 13.3183 15.0004L18.9976 22.9154C19.6079 23.7915 20.3569 24.7408 20.9145 25.6047V25.6043Z"
        fill="black"
      />
    </svg>
  );
}
