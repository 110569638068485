import classes from "./icons.module.css";

export default function AdminIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M12.5 23.9583C6.7115 22.6718 2.08337 17.2104 2.08337 11.4583V5.20829L12.5 1.04163L22.9167 5.20829V11.4583C22.9167 17.2125 18.2886 22.6718 12.5 23.9583ZM4.16671 6.24996V11.4583C4.22624 13.8668 5.07333 16.1894 6.57835 18.0706C8.08337 19.9519 10.1634 21.2882 12.5 21.875C14.8367 21.2882 16.9167 19.9519 18.4217 18.0706C19.9267 16.1894 20.7738 13.8668 20.8334 11.4583V6.24996L12.5 3.12496L4.16671 6.24996Z"
        fill="#fff"
      />
      <path
        d="M12.5 11.4583C13.9383 11.4583 15.1042 10.2924 15.1042 8.85417C15.1042 7.41593 13.9383 6.25 12.5 6.25C11.0618 6.25 9.89587 7.41593 9.89587 8.85417C9.89587 10.2924 11.0618 11.4583 12.5 11.4583Z"
        fill="#fff"
      />
      <path
        d="M7.29163 15.625C7.80505 16.5606 8.55787 17.343 9.47302 17.8921C10.3882 18.4412 11.4328 18.7373 12.5 18.75C13.5671 18.7373 14.6117 18.4412 15.5269 17.8921C16.442 17.343 17.1949 16.5606 17.7083 15.625C17.6823 13.65 14.227 12.5 12.5 12.5C10.7635 12.5 7.31767 13.65 7.29163 15.625Z"
        fill="#fff"
      />
    </svg>
  );
}
