import { Link } from "react-router-dom"

const TestimonialCard = ({content: {image,text,name}}) => {
  return (
    <div className='flex gap-5 items-center justify-center max-lg:flex-col'>
        <Link to={"/"}><img src={image} alt="testimonial" className='rounded-[50%] aspect-square object-cover max-lg:w-[150px]' width={250} />
        </Link> 
        <div className='max-w-[500px] text-center'>
            <h2 className='capitalize font-semibold text-2xl'>
                {name}
            </h2>
            <p className="text-xl max-lg:text-lg text-opacity-30 opacity-50">
            “{text}”
            </p>
        </div>
    </div>
  )
}

export default TestimonialCard