export const testimonial =[
    {
        image: "https://res.cloudinary.com/dxiqrdmzu/image/upload/q_auto/krater_soundmac_phlfs8.jpg",
        name: "Kraterboy777",
        text: "SoundMac has been a game changer for my music. Their affordable music distribution pricing plans have allowed me to pay using my local bank card and  to reach a global audience on platforms like Spotify, Apple Music, and TikTok. I've seen a significant increase in my streaming revenue since switching to SoundMac!",
    },
    {
        image: "https://res.cloudinary.com/dxiqrdmzu/image/upload/q_auto/success_soundmac_jja7ox.jpg",
        name: "SuccessMuch",
        text: "As an African artist, I was struggling to find a reliable music distribution service that understood my needs. SoundMac's music distribution tips and industry insights have been invaluable in helping me grow my fanbase and maximize my streaming revenue. Their customer support is top-notch too!",
    },
    {
        image: "https://res.cloudinary.com/dxiqrdmzu/image/upload/q_auto/svmmer_soundmac_qke8zh.jpg",
        name:"SvmmerBoy",
        text: "I was blown away by SoundMac's free online audio converter. It's so easy to convert mp3 to wav and has saved me so much time when preparing my tracks for distribution. The quality of the conversions is exceptional too. SoundMac is now my go-to platform for all my music distribution needs!",
    },
    {
        image: "https://res.cloudinary.com/dxiqrdmzu/image/upload/q_auto/jb_record_soundmac_x7kxwg.jpg",
        name: "Jb Records",
        text: "SoundMac has revolutionized the way we distribute music for our artists. Their music distribution API has streamlined our workflow, allowing us to focus on what matters most - finding and promoting new talent. The analytics and reporting tools are also incredibly detailed, helping us make data-driven decisions.",
    },
    {
        image: "https://res.cloudinary.com/dxiqrdmzu/image/upload/q_auto/david_soundmac_dnakm4.jpg",
        name: "febi Music Distribution",
        text:"We integrated SoundMac's music distribution API into our own platform, and it's been a seamless experience. The the support team is always available to help. We've seen a significant increase in our music distribution capabilities, and our users love the expanded reach.",
    },
    {
        image: "https://res.cloudinary.com/dxiqrdmzu/image/upload/q_auto/jane_soundmac_bip2tx.jpg",
        name: "White Day Music distribution",
        text: "SoundMac's white label services have allowed us to offer our own branded music distribution solution to our clients. The customization options are vast, and the support team has been fantastic in helping us set everything up. We're now able to offer a more comprehensive service to our clients, thanks to SoundMac.",
    }
]