import classes from "./anchor.module.css";

export default function AnchorArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15px"
      height="15px"
      viewBox="0 0 25 25"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M15.0781 12.5L7.42185 4.84377C7.16143 4.58335 7.03539 4.27502 7.04372 3.91877C7.05206 3.56252 7.18678 3.25453 7.44789 2.99481C7.70831 2.7344 8.01664 2.60419 8.37289 2.60419C8.72914 2.60419 9.03713 2.7344 9.29685 2.99481L17.2916 11.0156C17.5 11.224 17.6562 11.4584 17.7604 11.7188C17.8646 11.9792 17.9166 12.2396 17.9166 12.5C17.9166 12.7604 17.8646 13.0209 17.7604 13.2813C17.6562 13.5417 17.5 13.7761 17.2916 13.9844L9.27081 22.0052C9.01039 22.2656 8.70657 22.3917 8.35935 22.3834C8.01212 22.375 7.70831 22.2403 7.44789 21.9792C7.18747 21.7188 7.05726 21.4104 7.05726 21.0542C7.05726 20.6979 7.18747 20.39 7.44789 20.1302L15.0781 12.5Z"
        fill="#228699"
      />
    </svg>
  );
}
