import logo from '../assets/icons/logo.svg'


const Loader = () => {
  return (
    <div className='root h-[100dvh] text-white w-full'>
      <div className='flex flex-col justify-center w-full items-center h-full bg-black'>
        <div>
          <img src={logo} className="logo react" alt="React logo" />
        </div>
        <h1 className='text-lg font-bold'>Loading</h1>
      </div>
      
    </div>
  )
}

export default Loader