import classes from "./whatsappIcon.module.css";

export default function WhatsappIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M15.875 4.09167C15.111 3.31994 14.201 2.70806 13.1981 2.29171C12.1951 1.87536 11.1193 1.66288 10.0334 1.66667C5.48337 1.66667 1.77504 5.375 1.77504 9.925C1.77504 11.3833 2.15837 12.8 2.87504 14.05L1.70837 18.3333L6.08337 17.1833C7.29171 17.8417 8.65004 18.1917 10.0334 18.1917C14.5834 18.1917 18.2917 14.4833 18.2917 9.93333C18.2917 7.725 17.4334 5.65 15.875 4.09167ZM10.0334 16.7917C8.80004 16.7917 7.59171 16.4583 6.53337 15.8333L6.28337 15.6833L3.68337 16.3667L4.37504 13.8333L4.20837 13.575C3.52316 12.4808 3.15932 11.216 3.15837 9.925C3.15837 6.14167 6.24171 3.05833 10.025 3.05833C11.8584 3.05833 13.5834 3.775 14.875 5.075C15.5146 5.71164 16.0215 6.46889 16.3662 7.30286C16.7109 8.13682 16.8867 9.03093 16.8834 9.93333C16.9 13.7167 13.8167 16.7917 10.0334 16.7917ZM13.8 11.6583C13.5917 11.5583 12.575 11.0583 12.3917 10.9833C12.2 10.9167 12.0667 10.8833 11.925 11.0833C11.7834 11.2917 11.3917 11.7583 11.275 11.8917C11.1584 12.0333 11.0334 12.05 10.825 11.9417C10.6167 11.8417 9.95004 11.6167 9.16671 10.9167C8.55004 10.3667 8.14171 9.69167 8.01671 9.48333C7.90004 9.275 8.00004 9.16667 8.10837 9.05833C8.20004 8.96667 8.31671 8.81667 8.41671 8.7C8.51671 8.58333 8.55837 8.49167 8.62504 8.35833C8.69171 8.21667 8.65837 8.1 8.60837 8C8.55837 7.9 8.14171 6.88333 7.97504 6.46667C7.80837 6.06667 7.63337 6.11667 7.50837 6.10833H7.10837C6.96671 6.10833 6.75004 6.15833 6.55837 6.36667C6.37504 6.575 5.84171 7.075 5.84171 8.09167C5.84171 9.10833 6.58337 10.0917 6.68337 10.225C6.78337 10.3667 8.14171 12.45 10.2084 13.3417C10.7 13.5583 11.0834 13.6833 11.3834 13.775C11.875 13.9333 12.325 13.9083 12.6834 13.8583C13.0834 13.8 13.9084 13.3583 14.075 12.875C14.25 12.3917 14.25 11.9833 14.1917 11.8917C14.1334 11.8 14.0084 11.7583 13.8 11.6583Z"
        fill="white"
      />
    </svg>
  );
}
