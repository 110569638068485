import classes from "./icons.module.css";

export default function UpgradeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M9.375 7.81246H5.29688C6.30731 6.25959 7.79252 5.07483 9.53111 4.43479C11.2697 3.79474 13.1686 3.73369 14.9447 4.26073C16.7208 4.78777 18.2791 5.87467 19.3872 7.35943C20.4953 8.84419 21.0939 10.6473 21.0938 12.5H22.6563C22.6584 10.4105 22.0161 8.37122 20.8168 6.6602C19.6175 4.94918 17.9197 3.64969 15.9548 2.93892C13.99 2.22815 11.8537 2.14071 9.83733 2.6885C7.82096 3.2363 6.02261 4.39268 4.68751 5.99996V3.12496H3.12501V9.37496H9.375V7.81246ZM15.625 17.1875H19.7031C18.6927 18.7403 17.2075 19.9251 15.4689 20.5651C13.7303 21.2052 11.8314 21.2662 10.0553 20.7392C8.27917 20.2122 6.72092 19.1252 5.61283 17.6405C4.50474 16.1557 3.90614 14.3526 3.90626 12.5H2.34376C2.3416 14.5894 2.98396 16.6287 4.18323 18.3397C5.3825 20.0507 7.08032 21.3502 9.04518 22.061C11.01 22.7718 13.1463 22.8592 15.1627 22.3114C17.1791 21.7636 18.9774 20.6072 20.3125 19V21.875H21.875V15.625H15.625V17.1875Z"
        fill="#228699"
      />
    </svg>
  );
}
