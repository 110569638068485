import { useState } from "react";
import { testimonial } from "./data";
import { Circle, CircleDot,ArrowLeft,ArrowRight } from "lucide-react";
import TestimonialCard from "./TestimonialCard";

const Testimonial = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);



  // Handle touch start
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    document.body.style.overflow = 'hidden'; 
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  };
  
  // Handle touch end (determine swipe direction)
  const handleTouchEnd = () => {
    if (startX - endX > 20) {
      if(imageIndex === testimonial.length-1){
        return
      }
      setImageIndex(imageIndex+1); // Swipe left
    }
    if (startX - endX < -20) {
      if(imageIndex <= 0){
        return
      }
      setImageIndex(imageIndex-1) // Swipe right
    }

    document.body.style.overflow = 'auto';
  };

  return (
    <div>
      <section className="">
        <div className="w-full min-h-[500px] mx-auto py-10 bg-white">
          <div className="w-full h-full relative">
            <h1 className="text-center text-2xl mb-5 font-bold">
            SoundMac Success Stories: hear from our Artists & Labels
            </h1>
            <button className="absolute top-[50%] left-[11%] max-lg:left-[2%] rounded-full p-3 hover:text-white hover:bg-black max-md:p-2" onClick={()=>{
              setImageIndex(imageIndex-1)
            }} disabled={imageIndex <= 0}>
              <ArrowLeft/>
            </button>
            <div className=" w-[70%] mx-auto h-full flex overflow-hidden items-center bg-white bg-opacity-80 rounded-lg">
              {testimonial.map((item) => (
                <div
                  className={`block object-cover w-full overflow-hidden flex-shrink-0 flex-grow-0 transition-all duration-700 ease-in-out`}
                  style={{ translate: `${-100 * imageIndex}%` }}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                >
                  <TestimonialCard content={item} />
                  
                </div>
              ))}
            </div>
            <div className=" bottom-0 left-[50%] flex gap-3 my-5 justify-center w-full">
              {testimonial.map((item, index) => (
                <button
                  key={index}
                  onClick={() => {
                    setImageIndex(index);
                  }}
                >
                  {index === imageIndex ? (
                    <CircleDot className="w-[15px] h-[15px] text-[#1eaaf1] bg-[#1eaaf1] rounded-full transition-all duration-300ms" />
                  ) : (
                    <Circle className="w-[15px] h-[15px]" />
                  )}
                </button>
              ))}
            </div>
            <button className="absolute top-[50%] right-[11%] max-lg:right-[2%] rounded-full p-3 hover:text-white hover:bg-black max-md:p-2" onClick={()=>{
              setImageIndex(imageIndex+1)
            }} disabled={imageIndex===testimonial.length-1}>
              <ArrowRight/>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;



