import React from "react";

const Hero = () => {
  return (
    <section>
      <div className="grad img relative flex h-[550px] max-md:h-[400px] max-w-full justify-between overflow-hidden">
        <img src="https://res.cloudinary.com/dxiqrdmzu/image/upload/v1725317242/Blog/hijehpjqenuzutnh2eaa.png" alt="" className="z-20" />
        <img src="https://res.cloudinary.com/dxiqrdmzu/image/upload/v1725317240/Blog/hdrkw4hhtwzqnb4prbp6.png" alt="" className="z-20" />
        <img src="https://res.cloudinary.com/dxiqrdmzu/image/upload/v1725317241/Blog/oyufmdahzty7ddqdxvqm.png" alt="" className="absolute h-full w-full" />
        <div className="absolute bottom-0 left-[0%] top-0 z-30 flex h-full w-full flex-col items-center justify-center bg-black bg-opacity-50 text-center text-4xl text-white text-opacity-100 max-md:text-2xl">
          <p className="font-semibold leading-normal">Stay Tuned</p>
          <p className="font-semibold leading-normal">
            with the Latest Music Trends
          </p>
          <p className="max-w-xl text-center text-lg font-medium leading-normal max-md:text-sm">
            Discover new artists, tracks, and industry updates. Your one-stop
            destination for all things music
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
