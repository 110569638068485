import classes from "./logoIcon.module.css";

export default function LogoIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="55"
      viewBox="0 0 54 55"
      fill="none"
      className={classes.icon}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5272 4.44959C16.9534 4.07948 17.0194 4.01229 17.6925 3.70783C19.857 2.72881 26.3968 1.40133 28.7896 2.04034L29.2408 2.22503C30.7116 2.22503 33.0578 2.2971 34.3258 3.17848C35.8354 3.17848 34.9287 3.17135 35.2794 3.49602C36.8129 3.62363 38.7108 4.44685 39.4113 5.40304C40.5949 5.67895 41.6893 6.44057 42.2718 7.31006C45.0231 8.04468 49.9107 15.6116 51.0444 19.5145C52.9281 25.9972 52.3861 31.8571 50.0856 37.3729C45.9424 47.3077 35.3322 53.3861 23.5151 52.1293C12.7154 50.9806 3.93035 41.8704 2.17235 31.5178C0.450024 21.3754 4.02085 14.5983 10.0864 8.49744C11.2141 7.36334 14.7373 4.59861 16.5271 4.44947L16.5272 4.44959ZM27.1761 0H28.0632C31.2195 0.105846 35.5823 1.27408 38.1447 2.53805C60.0517 13.3453 58.2291 41.7215 38.9954 51.3914C31.9417 54.9373 21.7974 55.0177 14.8319 51.278C5.6648 46.3558 0 37.8782 0 27.0161C0 22.5406 1.03349 18.8723 2.5415 15.8905C4.48979 12.0378 5.06409 11.01 8.02458 8.02482C13.0455 2.96179 19.8004 0.380214 27.0429 0.00594642L27.1761 0Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6051 49.9002C21.1674 49.9002 16.2943 48.5547 10.8861 43.1463C8.94978 41.2101 7.59495 39.4082 6.34941 36.8766C1.15735 26.3252 4.89587 12.5134 16.9472 6.45859C26.0067 1.90696 36.4291 3.8939 43.7824 11.2029C46.6226 14.0259 48.6554 17.7017 49.5087 21.6862C50.5775 26.6796 50.3016 31.4822 48.3108 35.9151C44.7889 43.7587 37.0752 49.9002 28.6052 49.9002H28.6051ZM16.5274 4.44941L13.4739 6.48106C10.2928 8.58288 8.04985 10.9155 5.97276 14.2364C-0.398826 24.4224 2.32321 38.5853 11.1686 45.7238C19.8147 52.7009 31.0335 53.8147 40.18 47.808C42.4564 46.3132 43.8976 45.0796 45.6931 43.1501C53.8523 34.3808 53.8516 18.9405 44.8954 10.0898C43.9337 9.13923 42.991 8.38404 42.2722 7.31C41.3184 7.05549 39.9223 6.10014 39.4118 5.40298C38.4207 5.17214 38.2935 4.97614 37.3947 4.55942L35.2798 3.49596C33.7703 3.49596 34.677 3.5031 34.3263 3.17842L30.4878 2.56725C29.2362 2.34687 31.0715 2.74029 29.9503 2.46889L29.2411 2.22485C26.697 2.22485 24.4353 2.39754 22.4758 2.7699C21.256 3.00158 20.4603 3.19031 19.4219 3.52974C18.461 3.84359 17.6331 4.35748 16.5277 4.44941H16.5274Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3877 20.3416C19.3877 24.3527 22.1623 27.4081 25.647 28.0669C27.8303 28.4798 29.3961 28.2704 31.0681 29.6379C38.0838 35.3758 27.6872 45.0288 22.5016 37.8869C21.9486 37.1252 21.7808 36.3999 21.5586 35.3121C21.1494 33.312 21.5706 33.9929 21.6126 32.1013C20.8689 31.7079 20.7852 31.5555 19.7056 31.4657C19.6662 33.2256 18.32 37.1097 21.697 39.9628C30.2115 47.1569 39.5036 36.2858 33.2904 29.005C30.1893 25.3708 26.3602 27.2882 23.5988 25.0295C16.5425 19.2582 26.9338 9.64057 32.1643 16.7821C33.7173 18.9027 33.078 21.5125 33.0547 22.5662C33.81 22.966 34.1382 23.3001 34.9614 23.5197C34.9614 21.1537 36.8139 16.5746 30.9428 13.2361C26.5244 10.7236 19.3877 13.6419 19.3877 20.3416Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8839 20.0236C22.8839 26.7455 30.6039 22.9892 35.2003 28.6843C38.845 33.2008 36.7078 43.8613 27.3339 43.8613C21.5876 43.8613 17.7988 38.7641 17.7988 35.5977C17.7988 31.3865 19.4055 31.3227 17.7988 28.9232C14.778 30.0167 15.3006 38.9631 19.3394 42.6382C31.7346 53.9177 46.7789 34.4061 33.8784 24.921C32.268 23.7369 31.2909 23.5595 29.0035 23.1214C28.1728 22.9622 24.7908 22.5316 24.7908 20.6592C24.7908 17.2303 29.8763 15.8788 29.8763 21.6126C30.7345 21.6843 30.7408 21.7616 31.4653 21.9306C31.8467 20.2936 32.2198 18.9823 30.6162 17.3767C27.0845 13.8405 22.8838 17.2885 22.8838 20.0236H22.8839Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8916 19.07C15.8916 23.8812 16.782 26.5273 20.6032 29.6141C23.5963 32.0321 29.8763 31.2452 29.8763 34.008C29.8763 37.542 24.9161 38.6749 24.7908 33.0546C23.9326 32.9832 23.9263 32.9056 23.2018 32.7371C21.4277 40.3519 31.7833 40.7754 31.7833 34.0082C31.7833 32.2509 30.2058 30.6863 28.9025 30.2148C27.2038 29.6 24.897 29.6709 23.1954 28.9295C14.014 24.9295 17.8035 10.8064 26.3803 10.8064C29.4412 10.8064 31.8649 11.3361 34.1018 13.5731C39.7027 19.174 34.4656 23.7512 37.1865 25.7445C39.0064 24.4111 38.7755 21.8311 38.7755 19.7056C38.7755 10.4754 26.5537 5.00628 19.4636 12.1534C17.9219 13.7076 15.8917 16.1278 15.8917 19.07H15.8916Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0781 11.7599C13.0058 11.5122 13.9183 10.7802 15.0719 10.3038C20.5035 8.0622 21.7957 7.30998 27.9697 7.30998C36.6278 7.30998 43.0652 17.3661 39.3022 25.3219C38.34 27.3566 38.2807 27.2342 39.4118 28.9231C44.6881 25.3898 43.8273 5.40308 27.0162 5.40308C21.079 5.40308 14.4259 8.25414 12.0781 11.76V11.7599Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3955 33.6906C12.3955 42.9479 18.7119 49.2643 27.9692 49.2643C33.715 49.2643 40.3193 46.2978 42.5894 42.9077C40.5116 43.3919 32.7743 49.7226 22.356 46.2961C15.6286 44.0835 12.5413 35.5106 15.2606 29.5613C16.6386 26.5457 16.064 27.8491 15.5736 25.7446H14.9381C14.6227 26.9259 14.5908 26.393 13.9977 27.3468C13.046 28.8777 12.3955 31.68 12.3955 33.6906Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.6822 10.1707C41.0336 11.678 46.8957 19.1858 42.0576 27.7561C40.0778 31.2631 40.1349 30.2949 40.3645 33.0549C41.7613 32.682 43.34 29.6429 43.974 28.4007C47.6919 21.1177 45.419 11.4355 40.6822 10.1708V10.1707Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9843 44.4968C13.5838 42.9971 7.81136 35.268 12.5722 26.8749C14.4695 23.5302 14.9701 24.4792 14.3024 21.6125C13.174 21.914 13.4471 22.0761 12.3315 23.7737C11.7394 24.6742 11.241 25.3079 10.6993 26.2735C8.54301 30.1197 8.23486 36.9295 9.75072 40.1489C10.196 41.0945 10.833 41.8287 11.5147 42.5168C12.3053 43.3153 12.8162 44.1847 13.9845 44.4966L13.9843 44.4968Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.0469 38.1401C43.8823 36.1107 48.946 28.4557 48.946 24.4731C48.946 23.1604 48.7272 22.6187 48.4464 21.4767C48.124 20.1659 48.2047 19.691 47.357 19.0701C47.357 33.2957 40.047 32.5445 40.047 38.1401H40.0469Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72082 29.8767C5.72082 31.3475 6.1454 34.7445 7.30982 35.5978C7.30982 21.1345 14.6199 22.382 14.6199 16.5276C11.7676 18.0367 8.36995 22.5467 7.12323 25.5578C6.70995 26.5556 5.7207 28.835 5.7207 29.8767H5.72082Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72082 24.4732L6.42095 23.5843C6.67439 23.2059 6.79379 22.9566 7.01143 22.5858C11.8217 14.3928 15.6391 15.4881 18.1164 10.8066C15.888 10.9921 11.304 14.2854 9.92554 15.647C7.63747 17.9074 5.7207 20.0031 5.7207 24.4733L5.72082 24.4732Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5508 43.8613C38.7184 43.6812 43.1348 40.4501 44.5741 39.1715C46.8797 37.1233 48.946 34.752 48.946 30.5122C48.2669 31.01 47.0176 33.2947 46.3171 34.2395C43.6034 37.9004 42.4321 38.177 39.6786 40.315C39.3456 40.5733 39.1302 40.764 38.8186 41.044C38.7499 41.1055 38.5528 41.2836 38.4865 41.3473C38.4391 41.3924 38.3719 41.4584 38.3258 41.5045L36.5509 43.8613H36.5508Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9844 44.4968V44.8148L14.3553 44.5499L13.9844 44.4968Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.682 10.1706V9.85303L40.3115 10.1176L40.682 10.1706Z"
        fill={fill}
      />
    </svg>
  );
}
