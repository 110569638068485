import classes from "../icons.module.css";

export default function CancelIcon({ fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M12.352 12.352C12.5571 12.1472 12.8351 12.0322 13.1249 12.0322C13.4148 12.0322 13.6928 12.1472 13.8979 12.352L17.4999 15.9541L21.102 12.352C21.2022 12.2446 21.3229 12.1584 21.4571 12.0986C21.5912 12.0388 21.7361 12.0067 21.8829 12.0041C22.0298 12.0015 22.1757 12.0285 22.3119 12.0835C22.448 12.1385 22.5718 12.2204 22.6756 12.3243C22.7795 12.4281 22.8614 12.5518 22.9164 12.688C22.9714 12.8242 22.9984 12.9701 22.9958 13.117C22.9932 13.2638 22.9611 13.4086 22.9013 13.5428C22.8415 13.677 22.7553 13.7977 22.6479 13.8979L19.0458 17.4999L22.6479 21.102C22.8411 21.3094 22.9462 21.5836 22.9412 21.867C22.9362 22.1503 22.8214 22.4207 22.6211 22.6211C22.4207 22.8214 22.1503 22.9362 21.867 22.9412C21.5836 22.9462 21.3094 22.8411 21.102 22.6479L17.4999 19.0458L13.8979 22.6479C13.7977 22.7553 13.677 22.8415 13.5428 22.9013C13.4086 22.9611 13.2638 22.9932 13.117 22.9958C12.9701 22.9984 12.8242 22.9714 12.688 22.9164C12.5518 22.8614 12.4281 22.7795 12.3243 22.6756C12.2204 22.5718 12.1385 22.448 12.0835 22.3119C12.0285 22.1757 12.0015 22.0298 12.0041 21.8829C12.0067 21.7361 12.0388 21.5912 12.0986 21.4571C12.1584 21.3229 12.2446 21.2022 12.352 21.102L15.9541 17.4999L12.352 13.8979C12.1472 13.6928 12.0322 13.4148 12.0322 13.1249C12.0322 12.8351 12.1472 12.5571 12.352 12.352Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6705 5.49653C15.2095 4.99334 19.7902 4.99334 24.3292 5.49653C26.9936 5.79403 29.1447 7.89257 29.4567 10.5715C29.9963 15.1755 29.9963 19.8261 29.4567 24.4301C29.1432 27.109 26.9922 29.2061 24.3292 29.5051C19.7902 30.0083 15.2095 30.0083 10.6705 29.5051C8.00612 29.2061 5.85508 27.109 5.543 24.4301C5.00455 19.8262 5.00455 15.1754 5.543 10.5715C5.85508 7.89257 8.00758 5.79403 10.6705 5.49653ZM24.0872 7.66945C19.709 7.18416 15.2907 7.18416 10.9126 7.66945C10.1021 7.75936 9.3456 8.11989 8.7653 8.69278C8.18499 9.26568 7.81478 10.0175 7.71445 10.8267C7.1958 15.2615 7.1958 19.7416 7.71445 24.1763C7.81508 24.9853 8.18543 25.7368 8.76571 26.3094C9.34599 26.882 10.1023 27.2423 10.9126 27.3322C15.254 27.8163 19.7457 27.8163 24.0872 27.3322C24.8972 27.242 25.6532 26.8815 26.2331 26.309C26.8131 25.7364 27.1833 24.9851 27.2838 24.1763C27.8025 19.7416 27.8025 15.2615 27.2838 10.8267C27.183 10.0182 26.8127 9.26727 26.2327 8.69498C25.6528 8.12269 24.8969 7.76245 24.0872 7.67236"
        fill={fill}
      />
    </svg>
  );
}
