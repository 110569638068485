import classes from "./icons.module.css";

export default function PasswordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M9.37496 16.6667C9.37496 16.9429 9.26521 17.2079 9.06986 17.4032C8.87451 17.5986 8.60956 17.7083 8.33329 17.7083C8.05703 17.7083 7.79207 17.5986 7.59672 17.4032C7.40137 17.2079 7.29163 16.9429 7.29163 16.6667C7.29163 16.3904 7.40137 16.1254 7.59672 15.9301C7.79207 15.7347 8.05703 15.625 8.33329 15.625C8.60956 15.625 8.87451 15.7347 9.06986 15.9301C9.26521 16.1254 9.37496 16.3904 9.37496 16.6667ZM13.5416 16.6667C13.5416 16.9429 13.4319 17.2079 13.2365 17.4032C13.0412 17.5986 12.7762 17.7083 12.5 17.7083C12.2237 17.7083 11.9587 17.5986 11.7634 17.4032C11.568 17.2079 11.4583 16.9429 11.4583 16.6667C11.4583 16.3904 11.568 16.1254 11.7634 15.9301C11.9587 15.7347 12.2237 15.625 12.5 15.625C12.7762 15.625 13.0412 15.7347 13.2365 15.9301C13.4319 16.1254 13.5416 16.3904 13.5416 16.6667ZM17.7083 16.6667C17.7083 16.9429 17.5985 17.2079 17.4032 17.4032C17.2078 17.5986 16.9429 17.7083 16.6666 17.7083C16.3904 17.7083 16.1254 17.5986 15.9301 17.4032C15.7347 17.2079 15.625 16.9429 15.625 16.6667C15.625 16.3904 15.7347 16.1254 15.9301 15.9301C16.1254 15.7347 16.3904 15.625 16.6666 15.625C16.9429 15.625 17.2078 15.7347 17.4032 15.9301C17.5985 16.1254 17.7083 16.3904 17.7083 16.6667Z"
        fill="#228699"
      />
      <path
        d="M6.24998 10.4167V8.33334C6.24998 7.97917 6.27915 7.63021 6.33644 7.29167M18.75 10.4167V8.33334C18.7501 7.06379 18.3635 5.82433 17.6418 4.77989C16.9201 3.73545 15.8974 2.93556 14.7099 2.48668C13.5223 2.03779 12.2263 1.96119 10.9941 2.26707C9.76198 2.57295 8.6522 3.24681 7.81248 4.19896M11.4583 22.9167H8.33331C5.38748 22.9167 3.91352 22.9167 2.99894 22.001C2.08331 21.0865 2.08331 19.6125 2.08331 16.6667C2.08331 13.7208 2.08331 12.2469 2.99894 11.3323C3.91352 10.4167 5.38748 10.4167 8.33331 10.4167H16.6666C19.6125 10.4167 21.0864 10.4167 22.001 11.3323C22.9166 12.2469 22.9166 13.7208 22.9166 16.6667C22.9166 19.6125 22.9166 21.0865 22.001 22.001C21.0864 22.9167 19.6125 22.9167 16.6666 22.9167H15.625"
        stroke="#228699"
        strokeWidth="1.5625"
        strokeLinecap="round"
      />
    </svg>
  );
}
