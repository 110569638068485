import classes from "./icons.module.css";

export default function Accessibility() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={classes.icon}
    >
      <path
        d="M14.5832 7.29159C14.5832 7.84412 14.3637 8.37402 13.973 8.76472C13.5823 9.15542 13.0524 9.37492 12.4998 9.37492C11.9473 9.37492 11.4174 9.15542 11.0267 8.76472C10.636 8.37402 10.4165 7.84412 10.4165 7.29159C10.4165 6.73905 10.636 6.20915 11.0267 5.81845C11.4174 5.42775 11.9473 5.20825 12.4998 5.20825C13.0524 5.20825 13.5823 5.42775 13.973 5.81845C14.3637 6.20915 14.5832 6.73905 14.5832 7.29159Z"
        stroke="#228699"
        strokeWidth="1.5625"
      />
      <path
        d="M18.75 10.4165C18.75 10.4165 15.0656 11.979 12.5 11.979C9.93437 11.979 6.25 10.4165 6.25 10.4165M12.5 12.4998V14.0123M12.5 14.0123C12.4997 14.6118 12.6718 15.1986 12.9958 15.703L15.625 19.7915M12.5 14.0123C12.5003 14.6118 12.3282 15.1986 12.0042 15.703L9.375 19.7915"
        stroke="#228699"
        strokeWidth="1.5625"
        strokeLinecap="round"
      />
      <path
        d="M7.29159 3.47701C8.87442 2.56129 10.6713 2.08045 12.4999 2.08326C18.253 2.08326 22.9166 6.74681 22.9166 12.4999C22.9166 18.2531 18.253 22.9166 12.4999 22.9166C6.74679 22.9166 2.08325 18.2531 2.08325 12.4999C2.08325 10.6031 2.59054 8.82285 3.477 7.2916"
        stroke="#228699"
        strokeWidth="1.5625"
        strokeLinecap="round"
      />
    </svg>
  );
}
